@charset "utf-8";
@import url('bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    font-size: 13px;
    color: #3a3a3a;
    font-weight: 400;
}

* {
    margin: 0px;
    padding: 0px;
}

.clearfix {
    *zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
    position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

font-w {
    max-width: 100%;
}

table {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.fltL {
    float: left;
}

.fltR {
    float: right;
}

.padding_none {
    padding: 0 !important;
}

.border_none {
    border: none !important
}

.navbar-toggle {
    background-color: #fff !important;
}

.icon-bar {
    background-color: #000 !important;
}

.p_right0 {
    padding-right: 0px !important
}

.p_left0 {
    padding-left: 0px !important
}

input[type="date"] {
    text-transform: uppercase !important;
}

.Flex {
    display: flex;
}

.Green {
    color: #37a713;
}

.Red {
    color: #e21818;
}

.Blue {
    color: #139bbf;
}

.Yellow {
    color: #feca05;
}
.form-group {margin: 0px 0px 15px;position: relative;}
.form-group label {font-weight: 600;color: #000;font-size: 14px;display: block;}
.form-group .form-control {
    box-shadow: none;
    border: 1px solid #707070;
    border-radius: 40px;
    padding: 10px 10px 10px 10px;
    font-size: 15px;
    height: auto;
    color: #000;
    font-weight: 600;
}
.KioskPages {
    height: 1024px;
}
.KioskPages .Header {
    position: absolute;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
}
.KioskPages .Header .LogoMain{}
.KioskPages .Header .LogoMain figure {
    width: 150px;
}
.KioskPages .Header .HeaderRight{
    display: flex;
}
.KioskPages .Header figure {
    margin-right: 10px;
    width: 36px;
}
a.Button {
    background-color: #B00931;
    padding: 13px 40px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s all ease-in-out;
    outline: none;
    line-height: 24px;
    border-radius: 3px;
    display: inline-block;
    border: 1px solid #b00931;
}
a.Button :hover {
    background-color: #d98d00;
}
.MainBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 910px;
}
.MainBox .InnerBox {
    display: block;
    text-align: center;
}
.MainBox .InnerBox figure {
    margin: 0;
    width: 100%;
}
.StepBox {
    width: 630px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}
.StepBox .ShapeOne{

}
.StepBox .ShapeOne span {
    border: 1px dashed #B00931;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.StepBox .ShapeOne span::after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background: #B00931;
    border-radius: 50%;
}
.StepBox .ShapeTwo{

}
.StepBox .ShapeTwo span::after{
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background: #B4B4B4;
    border-radius: 50%;
}
.StepBox .ShapeOne p {
    font-size: 18px;
    color: #b00931;
    font-weight: 500;
    font-style: italic;
}
.StepBox .ShapeTwo span{
    border: 1px dashed #B4B4B4;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.StepBox .ShapeTwo p{
    color: #B4B4B4;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}
.StepBox .Line {
    border-top: 1px dashed #b00931;
    width: 314px;
    position: absolute;
    top: 14px;
    left: 30px;
}
.SelectOption {
    width: 1180px;
    padding: 50px 0px 0 0;
}
.FacilitiesBox ul li:hover span{
    background-color: #b00931;
    color: #fff;
}

.SelectOption .TitleBox {
    text-align: center;
    position: relative;
}
.SelectOption .TitleBox span {
    position: absolute;
    left: 0;
}
.SelectOption .TitleBox img {
    width: 25px;
}
.SelectOption .TitleBox h4 {
    font-size: 55px;
    color: rgb(240, 1, 1);
    font-weight: 600;
}
.FacilitiesBox{}

.FacilitiesBox ul {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -5px 0;
}
.FacilitiesBox2.FacilitiesBox ul{
    align-items: center;
    justify-content: center;
}
.FacilitiesBox2.FacilitiesBox ul li {
    display: flex;
    align-items: center;
    justify-content: center;
}
.FacilitiesBox2.FacilitiesBox ul li a{
    margin: 0 10px;
}
.FacilitiesBox ul li {
    position: relative;
    margin: 0 10px 20px;
    width: 48%;
}
.FacilitiesBox ul li input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.FacilitiesBox ul li span {
    border: 1px solid #b00931;
    box-sizing: border-box;
    border-radius: 3px;
    display: block;
    font-weight: 500;
    font-size: 28px;
    line-height: 19px;
    text-align: center;
    padding: 41px 0;
    font-family: 'Poppins';
    color: #b00931;
    box-shadow: 0 7px 9px #dce2e7;
}
.FacilitiesBox ul li input:checked~span {
    background: #B00931;
    color: #fff;
}
.FacilitiesBox ul li a.Button {
    width: 100%;
}
.FacilitiesBox ul li a.Button.No {
    color: #b00931;
    background: #fff;
}
.ButtonMain {
    margin-top: 30px;
}


.Footer{
    display: flex;

}
.Footer .LeftFooter {
    background: #fff;
    padding: 10px;
    width: 20%;
    display: flex;
    align-items: center;
}
.Footer .LeftFooter figure {
    width: 120px;
    margin: 0 auto;
    padding-top: 10px;
}
.Footer .MidFooter {
    background: #b00931;
    display: flex;
    align-items: center;
    width: 60%;
    color: #fff;
    font-size: 16px;
}

.Footer .MidFooter label{
    line-height: 20px;
    margin: 0;
    color: #fff;
}

.Footer .MidFooter span{
    display: inline-block;
    width: 70%;
    height: 2px;
}



.Footer .MidFooter p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    color: #fff;
}

.Footer .RightFooter {
    display: flex;
    padding: 10px;
    width: 20%;
    align-items: center;
    justify-content: center;
}
.Footer .RightFooter figure {
    width: 42px;
    margin: 0;
}
.Footer .RightFooter aside {
    padding-left: 20px;
}

.Footer .RightFooter h2 {
    font-size: 21px;
    color: #000;
    font-weight: 600;
}
.marquee p {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: scroll-left 2s linear infinite;
    -webkit-animation: scroll-left 2s linear infinite;
    animation: scroll-left 20s linear infinite;
}




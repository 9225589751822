@charset "utf-8";
@import url('bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px;
  color: #3a3a3a;
  font-weight: 400;
}

* {
  margin: 0px;
  padding: 0px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}

img {
  border: 0;
  outline: none;
  max-width: 100%;
}

font-w {
  max-width: 100%;
}

table {
  width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.fltL {
  float: left;
}

.fltR {
  float: right;
}

.padding_none {
  padding: 0 !important;
}

.border_none {
  border: none !important;
}

.navbar-toggle {
  background-color: #fff !important;
}

.icon-bar {
  background-color: #000 !important;
}

.p_right0 {
  padding-right: 0px !important;
}

.p_left0 {
  padding-left: 0px !important;
}

input[type='date'] {
  text-transform: uppercase !important;
}

.Flex {
  display: flex;
}

.Green {
  color: #37a713;
}

.Red {
  color: #e21818;
}

.Blue {
  color: #139bbf;
}

.Yellow {
  color: #feca05;
}
.form-group {
  margin: 0px 0px 15px;
  position: relative;
}
.form-group label {
  font-weight: 600;
  color: #000;
  font-size: 14px;
  display: block;
}
.form-group .form-control {
  box-shadow: none;
  border: 1px solid #707070;
  border-radius: 40px;
  padding: 10px 10px 10px 10px;
  font-size: 15px;
  height: auto;
  color: #000;
  font-weight: 600;
}
.SliderMain {
  position: relative;
}
.SliderMain .Logo {
  position: absolute;
  left: 30px;
  top: 20px;
  z-index: 11;
}
.SliderMain .Logo figure {
  width: 120px;
}
.SliderMain figure {
  margin: 0;
  min-height: 400px;
}
.SliderMain figure img {
  height: 400px;
}
.BackgroundColor {
  background: #00000054;
  width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 9;
}
.TerminalBox {
  overflow: hidden;
  min-height: 944px;
}
.TerminalBox .LeftBox {
  padding: 20px;
  background: #f5f6f7;
  min-height: 858px;
}
.TerminalBox .LeftBox ul li {
  padding-bottom: 22px;
}
.TerminalBox .LeftBox ul li:last-child {
  padding-bottom: 0;
}
/* .TerminalBox .LeftBox ul li span {
    font-size: 18px;
    color: #B00931;
    border: 1px solid #B00931;
    display: block;
    text-align: center;
    padding: 13px 10px;
    background: #E9E0E2;
    border-radius: 4px;
} */
.TerminalBox .LeftBox ul li span {
  font-size: 18px;
  color: #b00931;
  border: 1px solid #b00931;
  display: block;
  text-align: center;
  padding: 10px;
  background: #e9e0e2;
  border-radius: 4px;
}
.TerminalBox .TitleBox h4 {
  font-size: 40px;
  color: #000;
  margin-bottom: 22px;
  font-weight: 600;
  text-align: center;
}
.TerminalBox .RightBox {
  padding: 20px 0px 20px 20px;
}
.TerminalBox .RightBox .DetailBox {
  background: #b00931;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
.TerminalBox .RightBox .DetailBox h2 {
  font-weight: 600;
  font-size: 25px;
}
.TerminalBox .RightBox .DetailBox h6 {
  font-weight: 600;
}
.TerminalBox .RightBox .DetailBox p {
  font-size: 18px;
  color: #f0bbc8;
  line-height: 27px;
  padding-top: 10px;
  font-weight: 600;
}
.TerminalBox .RightBox .col-sm-3 {
  padding-right: 5px;
  padding-left: 5px;
}

/* .TerminalBox .LeftBox  ul {
    position: relative;
    animation: mymove 5s infinite;
  
  
}
  
@keyframes mymove {
    from {top: 0px;}
    100% {top: 617px;}
    to {top: 0px;}
    100% {top: 617px;}
  }

   */

.Footer {
  display: flex;
  box-shadow: 0 0 6px #e0dcdc;
}
.Footer .LeftFooter {
  background: #fff;
  padding: 10px;
  width: 20%;
  display: flex;
  align-items: center;
}
.Footer .LeftFooter figure {
  width: 160px;
  margin: 0 auto;
  padding-top: 10px;
}
.Footer .MidFooter {
  background: #b00931;
  display: flex;
  align-items: center;
  width: 60%;
  color: #fff;
  font-size: 16px;
}

.Footer .MidFooter label {
  line-height: 20px;
  margin: 0;
  color: #fff;
}

.Footer .MidFooter span {
  display: inline-block;
  width: 70%;
  height: 2px;
}

.Footer .MidFooter p {
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  color: #fff;
}
.Footer .RightFooter {
  display: flex;
  padding: 10px;
  width: 301px;
  align-items: center;
  justify-content: center;
}

.Footer .RightFooter h2 {
  font-size: 21px;
  color: #000;
  font-weight: 600;
}
.marquee p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

.TerminalBox .Logo {
  padding: 20px 0;
}
.TerminalBox .Logo figure {
  width: 160px;
  margin: 0 auto;
}
.SliderBox {
  background: #ffffff;
  padding-right: 20px;
  /* padding-left: 20px; */
}

.TableList {
  overflow: auto;
}

.TableList table {
  border: 1px solid #dcdcdc;
}

.TableList table tr:nth-child(odd) {
  background-color: #f5f2f2;
}

.TableList table tr:hover {
  background: #c7c7c7;
}

.TableList table tr th,
.TableList table tr td {
  padding: 10px 10px;
}

.TableList table tr th {
  background-color: #b40024;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  font-size: 45px;
  text-align: center;
  font-family: 'Roboto';
}
.TableList table tr td {
  font-weight: 500;
  color: #000;
  font-family: 'Roboto';
  font-size: 45px;
  text-align: center;
  height: 52px;
}
.TableList table tr td span.Counter {
  color: #b40024;
}
.TableList table tr td:first-child {
  border-right: 1px solid #dcdcdc;
}

.Language {
  display: inline-block;
  font-family: 'Roboto';
}
.Language select {
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 6px;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
}
